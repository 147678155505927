import React, { Component } from 'react';
import { connect } from 'react-redux';
import NoContentBox from '../../components/common/NoContentBox';
import {
  Accordion,
  AccordionItem,
  Button,
  InlineLoading,
  Search,
  Tile,
  TooltipIcon,
} from 'carbon-components-react';
import {
  getPersonalSummary,
  searchAiInsights,
  getAiInsightsStatusById,
} from '../../actions/CandidateInfo';
import { BsStars } from 'react-icons/bs';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { lowerAndTrim } from '../../utils/helpers';
import SuccErrNotification from '../../components/private/SuccErrNotification';
import { Help20 } from '@carbon/icons-react';

const renderHtml = (html) => {
  return (
    <div
      style={{ lineHeight: 1.3 }}
      className="xpa-ul pr-3"
      dangerouslySetInnerHTML={{ __html: html.replace(/\n/g, '<br />') }}
    />
  );
};

const inLineLoader = (
  <InlineLoading
    status="active"
    iconDescription="Loading"
    description={<div style={{ color: 'black' }}>Searching...</div>}
  />
);

class CandidatePersonalSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
      prompt: EditorState.createEmpty(),
      isAIGeneratedPromptAvail: false,
      taskId: null,
    };
    this.resultRef = React.createRef();
  }

  attempts = 0;

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.renderOnMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.taskId && this.state.taskId !== prevState.taskId) {
      this.handleGetTaskStatus();
    }

    if (
      prevState.evaluationResult !== this.state.evaluationResult &&
      this.resultRef.current
    ) {
      this.resultRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  componentWillUnmount() {
    this.props.onRef && this.props.onRef(undefined);
    this.attempts = 12;
  }

  renderOnMount = () => {
    const { appId } = this.props;
    if (appId) this.props.getPersonalSummary(appId);

    this.setState({ searchKey: '' });
  };

  handleOpenAccr = (name) => {
    this.setState({
      isOpenAccrVal: name,
    });
  };

  handleChange = (e) => {
    const { name, value } = e?.target || {};
    this.setState({
      [name]: value,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ prompt: editorState });
  };

  handleGetTaskStatus = async () => {
    const { taskId } = this.state;
    if (!taskId) return;

    const maxAttempts = 20;
    this.attempts = 0;

    while (this.attempts < maxAttempts) {
      this.attempts++;
      try {
        const res = await this.props.getAiInsightsStatusById(taskId);
        if (res && !res.error) {
          const { task_status, task_result } = res || {};
          const { custom_evaluation } = task_result || {};
          const { evaluation_result } = custom_evaluation || {};
          const finalTaskStatus = lowerAndTrim(task_status);
          if (finalTaskStatus === 'success') {
            this.setState({
              evaluationResult: evaluation_result,
              isAIGeneratedPromptAvail: true,
              taskId: null,
            });
            this.setState({
              taskId: null,
              errorNotif: false,
              successNotif: true,
              notifMsg: 'Generated Successfully!',
            });
            break;
          } else if (finalTaskStatus !== 'success') {
            await new Promise((resolve) => setTimeout(resolve, 5000)); // 5 seconds delay
          } else {
            this.setState({
              taskId: null,
              errorNotif: true,
              successNotif: false,
              errMsg: 'We have encountered something, Please try again.',
            });
            break;
          }
        } else {
          this.setState({
            taskId: null,
            errorNotif: true,
            successNotif: false,
            errMsg:
              res?.error?.message ||
              'Failed to Generate. Please try again later',
          });
          break;
        }
      } catch (error) {
        console.error('ERROR::', error);
        this.setState({
          taskId: null,
          errorNotif: true,
          successNotif: false,
          errMsg:
            error?.message || 'Failed to Generate. Please try again later',
        });
        break;
      }
    }

    if (this.attempts >= maxAttempts) {
      this.setState({
        taskId: null,
        errorNotif: true,
        successNotif: false,
        errMsg:
          'Oops! It looks like this is taking longer than usual. Please try again later',
      });
    }
  };

  handleSubmitPrompt = () => {
    const { prompt } = this.state;
    const content = prompt.getCurrentContent();
    const lengthOfTrimmedContent = content.getPlainText().trim().length;
    this.setState(
      {
        isPromptInvalid:
          !content.hasText() || !lengthOfTrimmedContent ? true : false,
      },
      () => {
        const { isPromptInvalid } = this.state;
        if (!isPromptInvalid) {
          const { appId } = this.props || {};
          const data = {
            appId,
            prompt: draftToHtml(convertToRaw(prompt.getCurrentContent())),
          };
          this.props
            .searchAiInsights(data)
            .then((res) => {
              const { task_id } = res || {};
              this.setState({ taskId: task_id });
            })
            .catch((error) => console.log('Error', error));
        }
      }
    );
  };

  handleCloseNotifBtn = () => {
    this.setState({
      successNotif: false,
      errorNotif: false,
      notifMsg: '',
    });
  };

  render() {
    const {
      searchKey,
      prompt,
      successNotif,
      errorNotif,
      notifMsg,
      errMsg,
      taskId,
      evaluationResult,
    } = this.state;
    const { personalSummary } = this.props;
    const { insights_result } = personalSummary || {};
    const { gems } = insights_result || {};

    return (
      <div>
        <div className="d-flex">
          <div className="">
            <div className="mt-2 h6 font-weight-bold">
              Candidate Overview - Key Traits
            </div>
          </div>

          <span className="xpa-gen-ai ml-3" onClick={() => {}}>
            <BsStars
              size={20}
              style={{ color: 'rgb(255 191 0)', marginRight: '2px' }}
            />
            Generated using AI
          </span>
        </div>
        <hr />

        <div className="mt-2 mb-3">
          <div style={{ fontWeight: 600, fontSize: 'medium' }}>
            Active Beta Testing
          </div>
          <div className="mt-2">
            <span style={{ fontWeight: 600, fontSize: 'small' }}>
              Disclaimer
            </span>{' '}
            -{' '}
            <span style={{ color: 'red', fontStyle: 'italic' }}>
              Please note that while in its beta phase, this product may not
              capture or record all data accurately and is subject to further
              modifications and improvements.
            </span>
          </div>
        </div>

        {gems && Array.isArray(gems) && gems.length > 0 ? (
          <Accordion className="" align="start">
            {gems.map((itm) => {
              const { gem_name, evaluation_result } = itm || {};
              return (
                <AccordionItem
                  className="gba-accor-content mt-2"
                  title={
                    <div className="bx--row align-items-center">
                      <div className="h6 font-weight-bold mb-1 bx--col-lg-12 bx--col-md-12">
                        {gem_name}
                      </div>
                    </div>
                  }>
                  <div style={{ lineHeight: 1.3 }}>{evaluation_result}</div>
                </AccordionItem>
              );
            })}
          </Accordion>
        ) : (
          <NoContentBox
            hideTile
            message="There are no AI Insights for your current selection"
          />
        )}

        {gems && Array.isArray(gems) && gems.length > 0 && (
          <>
            <hr />
            <div className="mb-5">
              <SuccErrNotification
                successNotif={successNotif}
                errorNotif={errorNotif}
                notifMsg={notifMsg}
                handleCloseNotifBtn={this.handleCloseNotifBtn}
                errMsg={errMsg}
              />
              <div className="mb-2 font-weight-bold">
                What would you like to know about this candidate?
              </div>
              <Editor
                toolbarHidden
                editorState={prompt}
                onEditorStateChange={this.onEditorStateChange}
                wrapperClassName="custom-editor-wrapper"
                placeholder="How can I help?"
              />
              <div className="mt-3">
                <Button onClick={this.handleSubmitPrompt} disabled={taskId}>
                  {taskId ? inLineLoader : 'Submit'}
                </Button>
              </div>

              <div ref={this.resultRef}>
                {evaluationResult && (
                  <div className="mb-5 mt-3">
                    <h5 className="h6 mb-1 font-weight-bold">
                      Generative AI analysis of your query about this candidate
                      :
                    </h5>
                    <div>{renderHtml(evaluationResult)}</div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  personalSummary: state.CandidateInfo.personalSummary,
});

const mapDispatchToProps = {
  getPersonalSummary,
  searchAiInsights,
  getAiInsightsStatusById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidatePersonalSummary);
