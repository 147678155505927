//Get Analytics data (Dashboard)
export const REQUEST_GET_DASHBOARD_ANALYTICS =
  'REQUEST_GET_DASHBOARD_ANALYTICS';
export const SUCCESS_GET_DASHBOARD_ANALYTICS =
  'SUCCESS_GET_DASHBOARD_ANALYTICS';
export const ERROR_GET_DASHBOARD_ANALYTICS = 'ERROR_GET_DASHBOARD_ANALYTICS';

//Get candidate list details (Candidate List)
export const REQUEST_GET_CANDIDATE_LIST = 'REQUEST_GET_CANDIDATE_LIST';
export const SUCCESS_GET_CANDIDATE_LIST = 'SUCCESS_GET_CANDIDATE_LIST';
export const ERROR_GET_CANDIDATE_LIST = 'ERROR_GET_CANDIDATE_LIST';

//Get candidate Search details (Candidate List)
export const REQUEST_GET_CANDIDATE_SEARCH_LIST =
  'REQUEST_GET_CANDIDATE_SEARCH_LIST';
export const SUCCESS_GET_CANDIDATE_SEARCH_LIST =
  'SUCCESS_GET_CANDIDATE_SEARCH_LIST';
export const ERROR_GET_CANDIDATE_SEARCH_LIST =
  'ERROR_GET_CANDIDATE_SEARCH_LIST';

//Get candidate info details (Candidate info)
export const REQUEST_GET_CANDIDATE_INFO = 'REQUEST_GET_CANDIDATE_INFO';
export const SUCCESS_GET_CANDIDATE_INFO = 'SUCCESS_GET_CANDIDATE_INFO';
export const ERROR_GET_CANDIDATE_INFO = 'ERROR_GET_CANDIDATE_INFO';

//Get candidate info details (today interviews)
export const REQUEST_GET_CANDIDATE_INTERVIEWS_TODAY =
  'REQUEST_GET_CANDIDATE_INTERVIEWS_TODAY';
export const SUCCESS_GET_CANDIDATE_INTERVIEWS_TODAY =
  'SUCCESS_GET_CANDIDATE_INTERVIEWS_TODAY';
export const ERROR_GET_CANDIDATE_INTERVIEWS_TODAY =
  'ERROR_GET_CANDIDATE_INTERVIEWS_TODAY';

//Get candidate info details (panel members upcoming interviews)
export const REQUEST_GET_MY_UPCOMING_INTERVIEWS =
  'REQUEST_GET_MY_UPCOMING_INTERVIEWS';
export const SUCCESS_GET_MY_UPCOMING_INTERVIEWS =
  'SUCCESS_GET_MY_UPCOMING_INTERVIEWS';
export const ERROR_GET_MY_UPCOMING_INTERVIEWS =
  'ERROR_GET_MY_UPCOMING_INTERVIEWS';

//Get candidate info details (panel members past interviews)
export const REQUEST_GET_MY_PAST_INTERVIEWS = 'REQUEST_GET_MY_PAST_INTERVIEWS';
export const SUCCESS_GET_MY_PAST_INTERVIEWS = 'SUCCESS_GET_MY_PAST_INTERVIEWS';
export const ERROR_GET_MY_PAST_INTERVIEWS = 'ERROR_GET_MY_PAST_INTERVIEWS';

//Get candidate info details ( all upcoming interviews)
export const REQUEST_GET_ALL_UPCOMING_INTERVIEWS =
  'REQUEST_GET_ALL_UPCOMING_INTERVIEWS';
export const SUCCESS_GET_ALL_UPCOMING_INTERVIEWS =
  'SUCCESS_GET_ALL_UPCOMING_INTERVIEWS';
export const ERROR_GET_ALL_UPCOMING_INTERVIEWS =
  'ERROR_GET_ALL_UPCOMING_INTERVIEWS';

//Get candidate info details ( all past interviews)
export const REQUEST_GET_ALL_PAST_INTERVIEWS =
  'REQUEST_GET_ALL_PAST_INTERVIEWS';
export const SUCCESS_GET_ALL_PAST_INTERVIEWS =
  'SUCCESS_GET_ALL_PAST_INTERVIEWS';
export const ERROR_GET_ALL_PAST_INTERVIEWS = 'ERROR_GET_ALL_PAST_INTERVIEWS';

//Get Compare applications list
export const REQUEST_GET_COMPARE_APPLICATIONS_LIST =
  'REQUEST_GET_COMPARE_APPLICATIONS_LIST';
export const SUCCESS_GET_COMPARE_APPLICATIONS_LIST =
  'SUCCESS_GET_COMPARE_APPLICATIONS_LIST';
export const ERROR_GET_COMPARE_APPLICATIONS_LIST =
  'ERROR_GET_COMPARE_APPLICATIONS_LIST';

//GET User Login State
export const REQUEST_AUTH_STATE = 'REQUEST_AUTH_STATE';
export const ERROR_AUTH_STATE = 'ERROR_AUTH_STATE';
export const SUCCESS_AUTH_STATE = 'SUCCESS_AUTH_STATE';

//GET User Login Details
export const REQUEST_X0PA_DUSER = 'REQUEST_X0PA_DUSER';
export const ERROR_X0PA_DUSER = 'ERROR_X0PA_DUSER';
export const SUCCESS_X0PA_DUSER = 'SUCCESS_X0PA_DUSER';

//User Login
export const REQUEST_USER_LOGIN = 'REQUEST_USER_LOGIN';
export const ERROR_USER_LOGIN = 'ERROR_USER_LOGIN';
export const SUCCESS_USER_LOGIN = 'SUCCESS_USER_LOGIN';

//Get batch info details (Batch Info)
export const REQUEST_GET_BATCH_DAILY_REPORTS =
  'REQUEST_GET_BATCH_DAILY_REPORTS';
export const SUCCESS_GET_BATCH_DAILY_REPORTS =
  'SUCCESS_GET_BATCH_DAILY_REPORTS';
export const ERROR_GET_BATCH_DAILY_REPORTS = 'ERROR_GET_BATCH_DAILY_REPORTS';

//Get Admin Users list
export const REQUEST_GET_ADMIN_USERS_LIST = 'REQUEST_GET_ADMIN_USERS_LIST';
export const SUCCESS_GET_ADMIN_USERS_LIST = 'SUCCESS_GET_ADMIN_USERS_LIST';
export const ERROR_GET_ADMIN_USERS_LIST = 'ERROR_GET_ADMIN_USERS_LIST';

//Create New User
export const REQUEST_CREATE_NEW_USER = 'REQUEST_CREATE_NEW_USER';
export const SUCCESS_CREATE_NEW_USER = 'SUCCESS_CREATE_NEW_USER';
export const ERROR_CREATE_NEW_USER = 'ERROR_CREATE_NEW_USER';

//Delete Created User
export const REQUEST_DELETE_CREATED_USER = 'REQUEST_DELETE_CREATED_USER';
export const SUCCESS_DELETE_CREATED_USER = 'SUCCESS_DELETE_CREATED_USER';
export const ERROR_DELETE_CREATED_USER = 'ERROR_DELETE_CREATED_USER';

//Register New User
export const REQUEST_USER_REGISTER = 'REQUEST_USER_REGISTER';
export const SUCCESS_USER_REGISTER = 'SUCCESS_USER_REGISTER';
export const ERROR_USER_REGISTER = 'ERROR_USER_REGISTER';

//GET User Mappings
export const REQUEST_GET_USER_MAPPINGS = 'REQUEST_GET_USER_MAPPINGS';
export const SUCCESS_GET_USER_MAPPINGS = 'SUCCESS_GET_USER_MAPPINGS';
export const ERROR_GET_USER_MAPPINGS = 'ERROR_GET_USER_MAPPINGS';

//UPSERT User Mappings
export const REQUEST_UPSERT_USER_MAPPINGS = 'REQUEST_UPSERT_USER_MAPPINGS';
export const SUCCESS_UPSERT_USER_MAPPINGS = 'SUCCESS_UPSERT_USER_MAPPINGS';
export const ERROR_UPSERT_USER_MAPPINGS = 'ERROR_UPSERT_USER_MAPPINGS';

//Create Video Interview Rating Criteria
export const REQUEST_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA =
  'REQUEST_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA';
export const SUCCESS_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA =
  'SUCCESS_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA';
export const ERROR_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA =
  'ERROR_CREATE_VIDEO_INTERVIEW_RATING_CRITERIA';

//GET Video Interview Rating Criteria
export const REQUEST_GET_VIDEO_INTERVIEW_RATING_CRITERIA =
  'REQUEST_GET_VIDEO_INTERVIEW_RATING_CRITERIA';
export const SUCCESS_GET_VIDEO_INTERVIEW_RATING_CRITERIA =
  'SUCCESS_GET_VIDEO_INTERVIEW_RATING_CRITERIA';
export const ERROR_GET_VIDEO_INTERVIEW_RATING_CRITERIA =
  'ERROR_GET_VIDEO_INTERVIEW_RATING_CRITERIA';

//UPSERT Video Interview Rating Criteria
export const REQUEST_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA =
  'REQUEST_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA';
export const SUCCESS_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA =
  'SUCCESS_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA';
export const ERROR_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA =
  'ERROR_UPSERT_VIDEO_INTERVIEW_RATING_CRITERIA';

//Create Candidate Evaluation
export const REQUEST_CREATE_CANDIDATE_EVALUATION =
  'REQUEST_CREATE_CANDIDATE_EVALUATION';
export const SUCCESS_CREATE_CANDIDATE_EVALUATION =
  'SUCCESS_CREATE_CANDIDATE_EVALUATION';
export const ERROR_CREATE_CANDIDATE_EVALUATION =
  'ERROR_CREATE_CANDIDATE_EVALUATION';

//GET Candidate Evaluation
export const REQUEST_GET_CANDIDATE_EVALUATION =
  'REQUEST_GET_CANDIDATE_EVALUATION';
export const SUCCESS_GET_CANDIDATE_EVALUATION =
  'SUCCESS_GET_CANDIDATE_EVALUATION';
export const ERROR_GET_CANDIDATE_EVALUATION = 'ERROR_GET_CANDIDATE_EVALUATION';

//UPSERT Candidate Evaluation
export const REQUEST_UPSERT_CANDIDATE_EVALUATION =
  'REQUEST_UPSERT_CANDIDATE_EVALUATION';
export const SUCCESS_UPSERT_CANDIDATE_EVALUATION =
  'SUCCESS_UPSERT_CANDIDATE_EVALUATION';
export const ERROR_UPSERT_CANDIDATE_EVALUATION =
  'ERROR_UPSERT_CANDIDATE_EVALUATION';

//Upload high level reports
export const REQUEST_UPLOAD_REPORTS_CSV = 'REQUEST_UPLOAD_REPORTS_CSV';
export const SUCCESS_UPLOAD_REPORTS_CSV = 'SUCCESS_UPLOAD_REPORTS_CSV';
export const ERROR_UPLOAD_REPORTS_CSV = 'ERROR_UPLOAD_REPORTS_CSV';

//Get high level reports
export const REQUEST_GET_REPORT_LOGS = 'REQUEST_GET_REPORT_LOGS';
export const SUCCESS_GET_REPORT_LOGS = 'SUCCESS_GET_REPORT_LOGS';
export const ERROR_GET_REPORT_LOGS = 'ERROR_GET_REPORT_LOGS';

//GET Candidate Video Intevriew Details
export const REQUEST_GET_VIDEO_INTERVIEW_DETAILS =
  'REQUEST_GET_VIDEO_INTERVIEW_DETAILS';
export const SUCCESS_GET_VIDEO_INTERVIEW_DETAILS =
  'SUCCESS_GET_VIDEO_INTERVIEW_DETAILS';
export const ERROR_GET_VIDEO_INTERVIEW_DETAILS =
  'ERROR_GET_VIDEO_INTERVIEW_DETAILS';

//Create VI Panel Members
export const REQUEST_CREATE_VI_PANEL_USERS = 'REQUEST_CREATE_VI_PANEL_USERS';
export const SUCCESS_CREATE_VI_PANEL_USERS = 'SUCCESS_CREATE_VI_PANEL_USERS';
export const ERROR_CREATE_VI_PANEL_USERS = 'ERROR_CREATE_VI_PANEL_USERS';

//Upsert VI Panel Members
export const REQUEST_UPSERT_VI_PANEL_USERS = 'REQUEST_UPSERT_VI_PANEL_USERS';
export const SUCCESS_UPSERT_VI_PANEL_USERS = 'SUCCESS_UPSERT_VI_PANEL_USERS';
export const ERROR_UPSERT_VI_PANEL_USERS = 'ERROR_UPSERT_VI_PANEL_USERS';

//Delete VI Panel Members
export const REQUEST_DELETE_VI_PANEL_USERS = 'REQUEST_DELETE_VI_PANEL_USERS';
export const SUCCESS_DELETE_VI_PANEL_USERS = 'SUCCESS_DELETE_VI_PANEL_USERS';
export const ERROR_DELETE_VI_PANEL_USERS = 'ERROR_DELETE_VI_PANEL_USERS';

//Get VI Panel Members
export const REQUEST_GET_VI_PANEL_USERS = 'REQUEST_GET_VI_PANEL_USERS';
export const SUCCESS_GET_VI_PANEL_USERS = 'SUCCESS_GET_VI_PANEL_USERS';
export const ERROR_GET_VI_PANEL_USERS = 'ERROR_GET_VI_PANEL_USERS';

//Generate CSC reports
export const REQUEST_GENERATE_HIGH_LVL_REPORTS =
  'REQUEST_GENERATE_HIGH_LVL_REPORTS';
export const SUCCESS_GENERATE_HIGH_LVL_REPORTS =
  'SUCCESS_GENERATE_HIGH_LVL_REPORTS';
export const ERROR_GENERATE_HIGH_LVL_REPORTS =
  'ERROR_GENERATE_HIGH_LVL_REPORTS';

//Generate CSC reports
export const REQUEST_GENERATE_CELERY_TASK_STATUS =
  'REQUEST_GENERATE_CELERY_TASK_STATUS';
export const SUCCESS_GENERATE_CELERY_TASK_STATUS =
  'SUCCESS_GENERATE_CELERY_TASK_STATUS';
export const ERROR_GENERATE_CELERY_TASK_STATUS =
  'ERROR_GENERATE_CELERY_TASK_STATUS';

//Get Test Norm Details
export const REQUEST_GET_TEST_NORM_DETAILS = 'REQUEST_GET_TEST_NORM_DETAILS';
export const SUCCESS_GET_TEST_NORM_DETAILS = 'SUCCESS_GET_TEST_NORM_DETAILS';
export const ERROR_GET_TEST_NORM_DETAILS = 'ERROR_GET_TEST_NORM_DETAILS';

//Get Test Details
export const REQUEST_GET_TEST_DETAILS = 'REQUEST_GET_TEST_DETAILS';
export const SUCCESS_GET_TEST_DETAILS = 'SUCCESS_GET_TEST_DETAILS';
export const ERROR_GET_TEST_DETAILS = 'ERROR_GET_TEST_DETAILS';

//Generate CSC DOwnload reports
export const REQUEST_DOWNLOAD_REPORTS_DATA = 'REQUEST_DOWNLOAD_REPORTS_DATA';
export const SUCCESS_DOWNLOAD_REPORTS_DATA = 'SUCCESS_DOWNLOAD_REPORTS_DATA';
export const ERROR_DOWNLOAD_REPORTS_DATA = 'ERROR_DOWNLOAD_REPORTS_DATA';

//Very Logged in email
export const REQUEST_VERIFY_EMAIL_DATA = 'REQUEST_VERIFY_EMAIL_DATA';
export const SUCCESS_VERIFY_EMAIL_DATA = 'SUCCESS_VERIFY_EMAIL_DATA';
export const ERROR_VERIFY_EMAIL_DATA = 'ERROR_VERIFY_EMAIL_DATA';

//Assign Candidate to CSC
export const REQUEST_ASSIGN_CSC_USER_VI = 'REQUEST_ASSIGN_CSC_USER_VI';
export const SUCCESS_ASSIGN_CSC_USER_VI = 'SUCCESS_ASSIGN_CSC_USER_VI';
export const ERROR_ASSIGN_CSC_USER_VI = 'ERROR_ASSIGN_CSC_USER_VI';

//Get Assigned Candidate list
export const REQUEST_GET_ASSIGNED_CANDIDATE_VI_LIST =
  'REQUEST_GET_ASSIGNED_CANDIDATE_VI_LIST';
export const SUCCESS_GET_ASSIGNED_CANDIDATE_VI_LIST =
  'SUCCESS_GET_ASSIGNED_CANDIDATE_VI_LIST';
export const ERROR_GET_ASSIGNED_CANDIDATE_VI_LIST =
  'ERROR_GET_ASSIGNED_CANDIDATE_VI_LIST';

//Get Norm Details
export const REQUEST_GET_DEFAULT_NORM_DETAILS =
  'REQUEST_GET_DEFAULT_NORM_DETAILS';
export const SUCCESS_GET_DEFAULT_NORM_DETAILS =
  'SUCCESS_GET_DEFAULT_NORM_DETAILS';
export const ERROR_GET_DEFAULT_NORM_DETAILS = 'ERROR_GET_DEFAULT_NORM_DETAILS';

//Get Assigned Candidate list
export const REQUEST_GET_CANDIDATE_PROFILE_DETAILS =
  'REQUEST_GET_CANDIDATE_PROFILE_DETAILS';
export const SUCCESS_GET_CANDIDATE_PROFILE_DETAILS =
  'SUCCESS_GET_CANDIDATE_PROFILE_DETAILS';
export const ERROR_GET_CANDIDATE_PROFILE_DETAILS =
  'ERROR_GET_CANDIDATE_PROFILE_DETAILS';

//Upload Candidate NRICS
export const REQUEST_UPLOAD_CANDIDATE_NRICS = 'REQUEST_UPLOAD_CANDIDATE_NRICS';
export const SUCCESS_UPLOAD_CANDIDATE_NRICS = 'SUCCESS_UPLOAD_CANDIDATE_NRICS';
export const ERROR_UPLOAD_CANDIDATE_NRICS = 'ERROR_UPLOAD_CANDIDATE_NRICS';

//GBA
export const REQUEST_GET_GBA_DETAILS = 'REQUEST_GET_GBA_DETAILS';
export const SUCCESS_GET_GBA_DETAILS = 'SUCCESS_GET_GBA_DETAILS';
export const ERROR_GET_GBA_DETAILS = 'ERROR_GET_GBA_DETAILS';

//Panel Interviewers
export const REQUEST_GET_SELECTION_BOARD_USERS =
  'REQUEST_GET_SELECTION_BOARD_USERS';
export const SUCCESS_GET_SELECTION_BOARD_USERS =
  'SUCCESS_GET_SELECTION_BOARD_USERS';
export const ERROR_GET_SELECTION_BOARD_USERS =
  'ERROR_GET_SELECTION_BOARD_USERS';

//Application patch actions
export const REQUEST_UPSERT_CANDIDATE_DETAILS_BY_ID =
  'REQUEST_UPSERT_CANDIDATE_DETAILS_BY_ID';
export const SUCCESS_UPSERT_CANDIDATE_DETAILS_BY_ID =
  'SUCCESS_UPSERT_CANDIDATE_DETAILS_BY_ID';
export const ERROR_UPSERT_CANDIDATE_DETAILS_BY_ID =
  'ERROR_UPSERT_CANDIDATE_DETAILS_BY_ID';

//Job Years list
export const REQUEST_GET_JOB_APPL_FILTERS = 'REQUEST_GET_JOB_APPL_FILTERS';
export const SUCCESS_GET_JOB_APPL_FILTERS = 'SUCCESS_GET_JOB_APPL_FILTERS';
export const ERROR_GET_JOB_APPL_FILTERS = 'ERROR_GET_JOB_APPL_FILTERS';

//Upsert User info details
export const REQUEST_UPSERT_USER_INFO_DETAILS_BY_ID =
  'REQUEST_UPSERT_USER_INFO_DETAILS_BY_ID';
export const SUCCESS_UPSERT_USER_INFO_DETAILS_BY_ID =
  'SUCCESS_UPSERT_USER_INFO_DETAILS_BY_ID';
export const ERROR_UPSERT_USER_INFO_DETAILS_BY_ID =
  'ERROR_UPSERT_USER_INFO_DETAILS_BY_ID';

//Assign Panel Interviewers to candidate
export const REQUEST_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE =
  'REQUEST_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE';
export const SUCCESS_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE =
  'SUCCESS_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE';
export const ERROR_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE =
  'ERROR_ASSIGN_PANEL_MEMBERS_TO_CANDIDATE';

//Assigned Panel Interviewers
export const REQUEST_GET_ASSIGNED_PANEL_INTERVIEWERS =
  'REQUEST_GET_ASSIGNED_PANEL_INTERVIEWERS';
export const SUCCESS_GET_ASSIGNED_PANEL_INTERVIEWERS =
  'SUCCESS_GET_ASSIGNED_PANEL_INTERVIEWERS';
export const ERROR_GET_ASSIGNED_PANEL_INTERVIEWERS =
  'ERROR_GET_ASSIGNED_PANEL_INTERVIEWERS';

//Assign Board Members for a date
export const REQUEST_ASSIGN_BOARD_USERS_FOR_A_DATE =
  'REQUEST_ASSIGN_BOARD_USERS_FOR_A_DATE';
export const SUCCESS_ASSIGN_BOARD_USERS_FOR_A_DATE =
  'SUCCESS_ASSIGN_BOARD_USERS_FOR_A_DATE';
export const ERROR_ASSIGN_BOARD_USERS_FOR_A_DATE =
  'ERROR_ASSIGN_BOARD_USERS_FOR_A_DATE';

//Get Second Rounders
export const REQUEST_GET_SECOND_ROUNDERS = 'REQUEST_GET_SECOND_ROUNDERS';
export const SUCCESS_GET_SECOND_ROUNDERS = 'SUCCESS_GET_SECOND_ROUNDERS';
export const ERROR_GET_SECOND_ROUNDERS = 'ERROR_GET_SECOND_ROUNDERS';

//New Dashboard PowerBI
export const REQUEST_PBI_TOKENS = 'REQUEST_PBI_TOKENS';
export const SUCCESS_PBI_TOKENS = 'SUCCESS_PBI_TOKENS';
export const ERROR_PBI_TOKENS = 'ERROR_PBI_TOKENS';

//Compare Applications

export const REQUEST_GET_COMPARE_APPL_FIELDS =
  'REQUEST_GET_COMPARE_APPL_FIELDS';
export const SUCCESS_GET_COMPARE_APPL_FIELDS =
  'SUCCESS_GET_COMPARE_APPL_FIELDS';
export const ERROR_GET_COMPARE_APPL_FIELDS = 'ERROR_GET_COMPARE_APPL_FIELDS';

export const REQUEST_UPSERT_COMPARE_APPL_FIELDS =
  'REQUEST_UPSERT_COMPARE_APPL_FIELDS';
export const SUCCESS_UPSERT_COMPARE_APPL_FIELDS =
  'SUCCESS_UPSERT_COMPARE_APPL_FIELDS';
export const ERROR_UPSERT_COMPARE_APPL_FIELDS =
  'ERROR_UPSERT_COMPARE_APPL_FIELDS';

//Upload psychological rating
export const REQUEST_UPLOAD_PSYCHOLOGICAL_RATING =
  'REQUEST_UPLOAD_PSYCHOLOGICAL_RATING';
export const SUCCESS_UPLOAD_PSYCHOLOGICAL_RATING =
  'SUCCESS_UPLOAD_PSYCHOLOGICAL_RATING';
export const ERROR_UPLOAD_PSYCHOLOGICAL_RATING =
  'ERROR_UPLOAD_PSYCHOLOGICAL_RATING';

//Assign candidates to other roles
export const REQUEST_ASSIGN_CANDIDATES_TO_OTHER_ROLES =
  'REQUEST_ASSIGN_CANDIDATES_TO_OTHER_ROLES';
export const SUCCESS_ASSIGN_CANDIDATES_TO_OTHER_ROLES =
  'SUCCESS_ASSIGN_CANDIDATES_TO_OTHER_ROLES';
export const ERROR_ASSIGN_CANDIDATES_TO_OTHER_ROLES =
  'ERROR_ASSIGN_CANDIDATES_TO_OTHER_ROLES';

//Get Report Types By APP Id
export const REQUEST_GET_REPORT_TYPES_BY_APP_ID =
  'REQUEST_GET_REPORT_TYPES_BY_APP_ID';
export const SUCCESS_GET_REPORT_TYPES_BY_APP_ID =
  'SUCCESS_GET_REPORT_TYPES_BY_APP_ID';
export const ERROR_GET_REPORT_TYPES_BY_APP_ID =
  'ERROR_GET_REPORT_TYPES_BY_APP_ID';

//Upload Candidate Reports
export const REQUEST_UPLOAD_CANDIDATE_REPORTS =
  'REQUEST_UPLOAD_CANDIDATE_REPORTS';
export const SUCCESS_UPLOAD_CANDIDATE_REPORTS =
  'SUCCESS_UPLOAD_CANDIDATE_REPORTS';
export const ERROR_UPLOAD_CANDIDATE_REPORTS = 'ERROR_UPLOAD_CANDIDATE_REPORTS';

//Delete Candidate Report
export const REQUEST_DELETE_CANDIDATE_REPORT = 'REQUEST_CANDIDATE_REPORT';
export const SUCCESS_DELETE_CANDIDATE_REPORT = 'SUCCESS_CANDIDATE_REPORT';
export const ERROR_DELETE_CANDIDATE_REPORT = 'ERROR_CANDIDATE_REPORT';

//Upload Batch Statistics
export const REQUEST_UPLOAD_BATCH_STATISTICS =
  'REQUEST_UPLOAD_BATCH_STATISTICS';
export const SUCCESS_UPLOAD_BATCH_STATISTICS =
  'SUCCESS_UPLOAD_BATCH_STATISTICS';
export const ERROR_UPLOAD_BATCH_STATISTICS = 'ERROR_UPLOAD_BATCH_STATISTICS';

//Upload Previous Batch Statistics
export const REQUEST_UPLOAD_PREVIOUS_BATCH_STATISTICS =
  'REQUEST_UPLOAD_PREVIOUS_BATCH_STATISTICS';
export const SUCCESS_UPLOAD_PREVIOUS_BATCH_STATISTICS =
  'SUCCESS_UPLOAD_PREVIOUS_BATCH_STATISTICS';
export const ERROR_UPLOAD_PREVIOUS_BATCH_STATISTICS =
  'ERROR_UPLOAD_PREVIOUS_BATCH_STATISTICS';

//Upload Custom Statistics
export const REQUEST_UPDATE_CUSTOM_STATISTICS =
  'REQUEST_UPDATE_CUSTOM_STATISTICS';
export const SUCCESS_UPDATE_CUSTOM_STATISTICS =
  'SUCCESS_UPDATE_CUSTOM_STATISTICS';
export const ERROR_UPDATE_CUSTOM_STATISTICS = 'ERROR_UPDATE_CUSTOM_STATISTICS';

//View Uploaded reports
export const REQUEST_VIEW_UPLOADED_REPORTS = 'REQUEST_VIEW_UPLOADED_REPORTS';
export const SUCCESS_VIEW_UPLOADED_REPORTS = 'SUCCESS_VIEW_UPLOADED_REPORTS';
export const ERROR_VIEW_UPLOADED_REPORTS = 'ERROR_VIEW_UPLOADED_REPORTS';

//Batch Last Uploaded History
export const REQUEST_LAST_UPLOADED_HISTORY = 'REQUEST_LAST_UPLOADED_HISTORY';
export const SUCCESS_LAST_UPLOADED_HISTORY = 'SUCCESS_LAST_UPLOADED_HISTORY';
export const ERROR_LAST_UPLOADED_HISTORY = 'ERROR_LAST_UPLOADED_HISTORY';

//Get Candidate Notes
export const REQUEST_GET_CANDIDATE_NOTES = 'REQUEST_GET_CANDIDATE_NOTES';
export const SUCCESS_GET_CANDIDATE_NOTES = 'SUCCESS_GET_CANDIDATE_NOTES';
export const ERROR_GET_CANDIDATE_NOTES = 'ERROR_GET_CANDIDATE_NOTES';

//Create New Candidate Notes
export const REQUEST_CREATE_NEW_CANDIDATE_NOTE =
  'REQUEST_CREATE_NEW_CANDIDATE_NOTE';
export const SUCCESS_CREATE_NEW_CANDIDATE_NOTE =
  'SUCCESS_CREATE_NEW_CANDIDATE_NOTE';
export const ERROR_CREATE_NEW_CANDIDATE_NOTE =
  'ERROR_CREATE_NEW_CANDIDATE_NOTE';

//Upsert Candidate Notes
export const REQUEST_UPSERT_CANDIDATE_NOTES = 'REQUEST_UPSERT_CANDIDATE_NOTES';
export const SUCCESS_UPSERT_CANDIDATE_NOTES = 'SUCCESS_UPSERT_CANDIDATE_NOTES';
export const ERROR_UPSERT_CANDIDATE_NOTES = 'ERROR_UPSERT_CANDIDATE_NOTES';

//Delete Candidate Notes
export const REQUEST_DELETE_CANDIDATE_NOTES = 'REQUEST_DELETE_CANDIDATE_NOTES';
export const SUCCESS_DELETE_CANDIDATE_NOTES = 'SUCCESS_DELETE_CANDIDATE_NOTES';
export const ERROR_DELETE_CANDIDATE_NOTES = 'ERROR_DELETE_CANDIDATE_NOTES';

//Candidate Report Download
export const REQUEST_DOWNLOAD_CANDIDATE_REPORT =
  'REQUEST_DOWNLOAD_CANDIDATE_REPORT';
export const SUCCESS_DOWNLOAD_CANDIDATE_REPORT =
  'SUCCESS_DOWNLOAD_CANDIDATE_REPORT';
export const ERROR_DOWNLOAD_CANDIDATE_REPORT =
  'ERROR_DOWNLOAD_CANDIDATE_REPORT';

//Upload Previous YTD Statistics
export const REQUEST_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS =
  'REQUEST_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS';
export const SUCCESS_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS =
  'SUCCESS_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS';
export const ERROR_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS =
  'ERROR_UPLOAD_PREVIOUS_YTD_BATCH_STATISTICS';

//Create Candidate Evaluation PS
export const REQUEST_CREATE_CANDIDATE_EVALUATION_PS =
  'REQUEST_CREATE_CANDIDATE_EVALUATION_PS';
export const SUCCESS_CREATE_CANDIDATE_EVALUATION_PS =
  'SUCCESS_CREATE_CANDIDATE_EVALUATION_PS';
export const ERROR_CREATE_CANDIDATE_EVALUATION_PS =
  'ERROR_CREATE_CANDIDATE_EVALUATION_PS';

//GET Candidate Evaluation PS
export const REQUEST_GET_CANDIDATE_EVALUATION_PS =
  'REQUEST_GET_CANDIDATE_EVALUATION_PS';
export const SUCCESS_GET_CANDIDATE_EVALUATION_PS =
  'SUCCESS_GET_CANDIDATE_EVALUATION_PS';
export const ERROR_GET_CANDIDATE_EVALUATION_PS =
  'ERROR_GET_CANDIDATE_EVALUATION_PS';

//UPSERT Candidate Evaluation PS
export const REQUEST_UPSERT_CANDIDATE_EVALUATION_PS =
  'REQUEST_UPSERT_CANDIDATE_EVALUATION_PS';
export const SUCCESS_UPSERT_CANDIDATE_EVALUATION_PS =
  'SUCCESS_UPSERT_CANDIDATE_EVALUATION_PS';
export const ERROR_UPSERT_CANDIDATE_EVALUATION_PS =
  'ERROR_UPSERT_CANDIDATE_EVALUATION_PS';

//Upload Assessment Center
export const REQUEST_UPLOAD_ASSESSMENT_CENTER_DATA =
  'REQUEST_UPLOAD_ASSESSMENT_CENTER_DATA';
export const SUCCESS_UPLOAD_ASSESSMENT_CENTER_DATA =
  'SUCCESS_UPLOAD_ASSESSMENT_CENTER_DATA';
export const ERROR_UPLOAD_ASSESSMENT_CENTER_DATA =
  'ERROR_UPLOAD_ASSESSMENT_CENTER_DATA';

//Upload Peer Ratings
export const REQUEST_UPLOAD_PEER_RATINGS_DATA =
  'REQUEST_UPLOAD_APEER_RATINGS_DATA';
export const SUCCESS_UPLOAD_PEER_RATINGS_DATA =
  'SUCCESS_UPLOAD_PEER_RATINGS_DATA';
export const ERROR_UPLOAD_PEER_RATINGS_DATA = 'ERROR_UPLOAD_PEER_RATINGS_DATA';

//Psac and Peer appraisal
export const REQUEST_GET_PSAC_PEER_PS_DATA = 'REQUEST_GET_PSAC_PEER_PS_DATA';
export const SUCCESS_GET_PSAC_PEER_PS_DATA = 'SUCCESS_GET_PSAC_PEER_PS_DATA';
export const ERROR_GET_PSAC_PEER_PS_DATA = 'ERROR_GET_PSAC_PEER_PS_DATA';

//Psac and Peer appraisal
export const REQUEST_GET_PSAC_PEER_PS_DATA_BY_ID =
  'REQUEST_GET_PSAC_PEER_PS_DATA_BY_ID';
export const SUCCESS_GET_PSAC_PEER_PS_DATA_BY_ID =
  'SUCCESS_GET_PSAC_PEER_PS_DATA_BY_ID';
export const ERROR_GET_PSAC_PEER_PS_DATA_BY_ID =
  'ERROR_GET_PSAC_PEER_PS_DATA_BY_ID';

//Candidate Selection Note Report Download
export const REQUEST_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT =
  'REQUEST_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT';
export const SUCCESS_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT =
  'SUCCESS_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT';
export const ERROR_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT =
  'ERROR_DOWNLOAD_SELECTION_NOTE_CANDIDATE_REPORT';

//Get candidate info details ( all past interviews)
export const REQUEST_GET_NEXT_INTERVIEW_DATE =
  'REQUEST_GET_NEXT_INTERVIEW_DATE';
export const SUCCESS_GET_NEXT_INTERVIEW_DATE =
  'SUCCESS_GET_NEXT_INTERVIEW_DATE';
export const ERROR_GET_NEXT_INTERVIEW_DATE = 'ERROR_GET_NEXT_INTERVIEW_DATE';

export const REQUEST_CREATE_BREAK_TIME = 'REQUEST_CREATE_BREAK_TIME';
export const SUCCESS_CREATE_BREAK_TIME = 'SUCCESS_CREATE_BREAK_TIME';
export const ERROR_CREATE_BREAK_TIME = 'ERROR_CREATE_BREAK_TIME';

export const REQUEST_DELETE_BREAK_TIME = 'REQUEST_DELETE_BREAK_TIME';
export const SUCCESS_DELETE_BREAK_TIME = 'SUCCESS_DELETE_BREAK_TIME';
export const ERROR_DELETE_BREAK_TIME = 'ERROR_DELETE_BREAK_TIME';

export const REQUEST_GET_BREAK_TIME = 'REQUEST_GET_BREAK_TIME';
export const SUCCESS_GET_BREAK_TIME = 'SUCCESS_GET_BREAK_TIME';
export const ERROR_GET_BREAK_TIME = 'ERROR_GET_BREAK_TIME';

export const REQUEST_UPDATE_TIMER_FOR_APPLS = 'REQUEST_UPDATE_TIMER_FOR_APPLS';
export const SUCCESS_UPDATE_TIMER_FOR_APPLS = 'SUCCESS_UPDATE_TIMER_FOR_APPLS';
export const ERROR_UPDATE_TIMER_FOR_APPLS = 'ERROR_UPDATE_TIMER_FOR_APPLS';

//GET ACTIVITY LOGS
export const REQUEST_GET_AUDIT_LOGS = 'REQUEST_GET_AUDIT_LOGS';
export const SUCCESS_GET_AUDIT_LOGS = 'SUCCESS_GET_AUDIT_LOGS';
export const ERROR_GET_AUDIT_LOGS = 'ERROR_GET_AUDIT_LOGS';

//Assign Candidates to Interview stage
export const REQUEST_ASSIGN_CAND_TO_INT_STAGE =
  'REQUEST_ASSIGN_CAND_TO_INT_STAGE';
export const SUCCESS_ASSIGN_CAND_TO_INT_STAGE =
  'SUCCESS_ASSIGN_CAND_TO_INT_STAGE';
export const ERROR_ASSIGN_CAND_TO_INT_STAGE = 'ERROR_ASSIGN_CAND_TO_INT_STAGE';

export const REQUEST_GET_STATIC_APPL_FILTERS =
  'REQUEST_GET_STATIC_APPL_FILTERS';
export const SUCCESS_GET_STATIC_APPL_FILTERS =
  'SUCCESS_GET_STATIC_APPL_FILTERS';
export const ERROR_GET_STATIC_APPL_FILTERS = 'ERROR_GET_STATIC_APPL_FILTERS';

//Upload Candidates through Excel
export const REQUEST_UPLOAD_CANDIDATES_DATA = 'REQUEST_UPLOAD_CANDIDATES_DATA';
export const SUCCESS_UPLOAD_CANDIDATES_DATA = 'SUCCESS_UPLOAD_CANDIDATES_DATA';
export const ERROR_UPLOAD_CANDIDATES_DATA = 'ERROR_UPLOAD_CANDIDATES_DATA';

//Update Video Interview Comments
export const REQUEST_UPDATE_VID_INT_COMMENTS =
  'REQUEST_UPDATE_VID_INT_COMMENTS';
export const SUCCESS_UPDATE_VID_INT_COMMENTS =
  'SUCCESS_UPDATE_VID_INT_COMMENTS';
export const ERROR_UPDATE_VID_INT_COMMENTS = 'ERROR_UPDATE_VID_INT_COMMENTS';

//Reset Panel Member Password
export const REQUEST_RESET_PANEL_MEM_PASSWORD =
  'REQUEST_RESET_PANEL_MEM_PASSWORD';
export const SUCCESS_RESET_PANEL_MEM_PASSWORD =
  'SUCCESS_RESET_PANEL_MEM_PASSWORD';
export const ERROR_RESET_PANEL_MEM_PASSWORD = 'ERROR_RESET_PANEL_MEM_PASSWORD';

export const REQUEST_GET_ASSESSMENT_SCENARIOS =
  'REQUEST_GET_ASSESSMENT_SCENARIOS';
export const SUCCESS_GET_ASSESSMENT_SCENARIOS =
  'SUCCESS_GET_ASSESSMENT_SCENARIOS';
export const ERROR_GET_ASSESSMENT_SCENARIOS = 'ERROR_GET_ASSESSMENT_SCENARIOS';

export const REQUEST_UPDATE_ASSESSMENT_SCENARIOS =
  'REQUEST_UPDATE_ASSESSMENT_SCENARIOS';
export const SUCCESS_UPDATE_ASSESSMENT_SCENARIOS =
  'SUCCESS_UPDATE_ASSESSMENT_SCENARIOS';
export const ERROR_UPDATE_ASSESSMENT_SCENARIOS =
  'ERROR_UPDATE_ASSESSMENT_SCENARIOS';

export const REQUEST_GET_ASSESSMENT_CANDIDATES =
  'REQUEST_GET_ASSESSMENT_CANDIDATES';
export const SUCCESS_GET_ASSESSMENT_CANDIDATES =
  'SUCCESS_GET_ASSESSMENT_CANDIDATES';
export const ERROR_GET_ASSESSMENT_CANDIDATES =
  'ERROR_GET_ASSESSMENT_CANDIDATES';

export const REQUEST_ADD_ASSESSMENT_CANDIDATE =
  'REQUEST_ADD_ASSESSMENT_CANDIDATE';
export const SUCCESS_ADD_ASSESSMENT_CANDIDATE =
  'SUCCESS_ADD_ASSESSMENT_CANDIDATE';
export const ERROR_ADD_ASSESSMENT_CANDIDATE = 'ERROR_ADD_ASSESSMENT_CANDIDATE';

export const REQUEST_UPDATE_ASSESSMENT_CANDIDATE =
  'REQUEST_UPDATE_ASSESSMENT_CANDIDATE';
export const SUCCESS_UPDATE_ASSESSMENT_CANDIDATE =
  'SUCCESS_UPDATE_ASSESSMENT_CANDIDATE';
export const ERROR_UPDATE_ASSESSMENT_CANDIDATE =
  'ERROR_UPDATE_ASSESSMENT_CANDIDATE';

export const REQUEST_ALL_COUNTRIES = 'REQUEST_ALL_COUNTRIES';
export const SUCCESS_ALL_COUNTRIES = 'SUCCESS_ALL_COUNTRIES';
export const ERROR_ALL_COUNTRIES = 'ERROR_ALL_COUNTRIES';

export const REQUEST_UPLOAD_ASSESS_CANDIDATES_DATA =
  'REQUEST_UPLOAD_ASSESS_CANDIDATES_DATA';
export const SUCCESS_UPLOAD_ASSESS_CANDIDATES_DATA =
  'SUCCESS_UPLOAD_ASSESS_CANDIDATES_DATA';
export const ERROR_UPLOAD_ASSESS_CANDIDATES_DATA =
  'ERROR_UPLOAD_ASSESS_CANDIDATES_DATA';

export const REQUEST_GET_ASSESSMENT_CANDIDATE_INFO =
  'REQUEST_GET_ASSESSMENT_CANDIDATE_INFO';
export const SUCCESS_GET_ASSESSMENT_CANDIDATE_INFO =
  'SUCCESS_GET_ASSESSMENT_CANDIDATE_INFO';
export const ERROR_GET_ASSESSMENT_CANDIDATE_INFO =
  'ERROR_GET_ASSESSMENT_CANDIDATE_INFO';

export const REQUEST_GET_ASSESS_COMPANY_TYPES =
  'REQUEST_GET_ASSESS_COMPANY_TYPES';
export const SUCCESS_GET_ASSESS_COMPANY_TYPES =
  'SUCCESS_GET_ASSESS_COMPANY_TYPES';
export const ERROR_GET_ASSESS_COMPANY_TYPES = 'ERROR_GET_ASSESS_COMPANY_TYPES';

export const REQUEST_GET_ASSESS_COMPANIES = 'REQUEST_GET_ASSESS_COMPANIES';
export const SUCCESS_GET_ASSESS_COMPANIES = 'SUCCESS_GET_ASSESS_COMPANIES';
export const ERROR_GET_ASSESS_COMPANIES = 'ERROR_GET_ASSESS_COMPANIES';

export const REQUEST_CREATE_ASSESS_COMPANY = 'REQUEST_CREATE_ASSESS_COMPANY';
export const SUCCESS_CREATE_ASSESS_COMPANY = 'SUCCESS_CREATE_ASSESS_COMPANY';
export const ERROR_CREATE_ASSESS_COMPANY = 'ERROR_CREATE_ASSESS_COMPANY';

// FEEDBACK TEMPLATES
export const REQUEST_CREATE_SCORECARD_TEMPLATE =
  'REQUEST_CREATE_SCORECARD_TEMPLATE';
export const SUCCESS_CREATE_SCORECARD_TEMPLATE =
  'SUCCESS_CREATE_SCORECARD_TEMPLATE';
export const ERROR_CREATE_SCORECARD_TEMPLATE =
  'ERROR_CREATE_SCORECARD_TEMPLATE';

export const REQUEST_GET_SCORECARD_TEMPLATES =
  'REQUEST_GET_SCORECARD_TEMPLATES';
export const SUCCESS_GET_SCORECARD_TEMPLATES =
  'SUCCESS_GET_SCORECARD_TEMPLATES';
export const ERROR_GET_SCORECARD_TEMPLATES = 'ERROR_GET_SCORECARD_TEMPLATES';

export const REQUEST_GET_SCORECARD_TEMPLATE_BY_ID =
  'REQUEST_GET_SCORECARD_TEMPLATE_BY_ID';
export const SUCCESS_GET_SCORECARD_TEMPLATE_BY_ID =
  'SUCCESS_GET_SCORECARD_TEMPLATE_BY_ID';
export const ERROR_GET_SCORECARD_TEMPLATE_BY_ID =
  'ERROR_GET_SCORECARD_TEMPLATE_BY_ID';

export const REQUEST_DELETE_SCORECARD_TEMPLATE_BY_ID =
  'REQUEST_DELETE_SCORECARD_TEMPLATE_BY_ID';
export const SUCCESS_DELETE_SCORECARD_TEMPLATE_BY_ID =
  'SUCCESS_DELETE_SCORECARD_TEMPLATE_BY_ID';
export const ERROR_DELETE_SCORECARD_TEMPLATE_BY_ID =
  'ERROR_DELETE_SCORECARD_TEMPLATE_BY_ID';

export const REQUEST_CREATE_SCORECARD_TEMPLATE_CONF_DATA =
  'REQUEST_CREATE_SCORECARD_TEMPLATE_CONF_DATA';
export const SUCCESS_CREATE_SCORECARD_TEMPLATE_CONF_DATA =
  'SUCCESS_CREATE_SCORECARD_TEMPLATE_CONF_DATA';
export const ERROR_CREATE_SCORECARD_TEMPLATE_CONF_DATA =
  'ERROR_CREATE_SCORECARD_TEMPLATE_CONF_DATA';

export const REQUEST_CREATE_RATING_CRITERIA_SCORECARD =
  'REQUEST_CREATE_RATING_CRITERIA_SCORECARD';
export const SUCCESS_CREATE_RATING_CRITERIA_SCORECARD =
  'SUCCESS_CREATE_RATING_CRITERIA_SCORECARD';
export const ERROR_CREATE_RATING_CRITERIA_SCORECARD =
  'ERROR_CREATE_RATING_CRITERIA_SCORECARD';

export const REQUEST_DELETE_RATING_CRITERIA_SCORECARD =
  'REQUEST_DELETE_RATING_CRITERIA_SCORECARD';
export const SUCCESS_DELETE_RATING_CRITERIA_SCORECARD =
  'SUCCESS_DELETE_RATING_CRITERIA_SCORECARD';
export const ERROR_DELETE_RATING_CRITERIA_SCORECARD =
  'ERROR_DELETE_RATING_CRITERIA_SCORECARD';

export const REQUEST_CREATE_APPLICANT_SCORECARD =
  'REQUEST_CREATE_APPLICANT_SCORECARD';
export const SUCCESS_CREATE_APPLICANT_SCORECARD =
  'SUCCESS_CREATE_APPLICANT_SCORECARD';
export const ERROR_CREATE_APPLICANT_SCORECARD =
  'ERROR_CREATE_APPLICANT_SCORECARD';

export const REQUEST_GET_APPLICANT_SCORECARD_BY_ID =
  'REQUEST_GET_APPLICANT_SCORECARD_BY_ID';
export const SUCCESS_GET_APPLICANT_SCORECARD_BY_ID =
  'SUCCESS_GET_APPLICANT_SCORECARD_BY_ID';
export const ERROR_GET_APPLICANT_SCORECARD_BY_ID =
  'ERROR_GET_APPLICANT_SCORECARD_BY_ID';

export const REQUEST_GET_ASSESSMENT_TYPES = 'REQUEST_GET_ASSESSMENT_TYPES';
export const SUCCESS_GET_ASSESSMENT_TYPES = 'SUCCESS_GET_ASSESSMENT_TYPES';
export const ERROR_GET_ASSESSMENT_TYPES = 'ERROR_GET_ASSESSMENT_TYPES';

export const REQUEST_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES =
  'REQUEST_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES';
export const SUCCESS_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES =
  'SUCCESS_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES';
export const ERROR_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES =
  'ERROR_GET_CANDIDATE_ASSESS_FEEDBACK_RESPONSES';

export const REQUEST_GET_CAND_ASSESS_STATUS = 'REQUEST_GET_CAND_ASSESS_STATUS';
export const SUCCESS_GET_CAND_ASSESS_STATUS = 'SUCCESS_GET_CAND_ASSESS_STATUS';
export const ERROR_GET_CAND_ASSESS_STATUS = 'ERROR_GET_CAND_ASSESS_STATUS';

export const REQUEST_GET_LEAP_DETAILS = 'REQUEST_GET_LEAP_DETAILS';
export const SUCCESS_GET_LEAP_DETAILS = 'SUCCESS_GET_LEAP_DETAILS';
export const ERROR_GET_LEAP_DETAILS = 'ERROR_GET_LEAP_DETAILS';

export const REQUEST_UPDATE_CAND_ASSESS_LINK_STATUS =
  'REQUEST_UPDATE_CAND_ASSESS_LINK_STATUS';
export const SUCCESS_UPDATE_CAND_ASSESS_LINK_STATUS =
  'SUCCESS_UPDATE_CAND_ASSESS_LINK_STATUS';
export const ERROR_UPDATE_CAND_ASSESS_LINK_STATUS =
  'ERROR_UPDATE_CAND_ASSESS_LINK_STATUS';

export const REQUEST_GET_ALL_SCORES = 'REQUEST_GET_ALL_SCORES';
export const SUCCESS_GET_ALL_SCORES = 'SUCCESS_GET_ALL_SCORES';
export const ERROR_GET_ALL_SCORES = 'ERROR_GET_ALL_SCORES';

export const REQUEST_UPLOAD_SCHOOLS_LIST = 'REQUEST_UPLOAD_SCHOOLS_LIST';
export const SUCCESS_UPLOAD_SCHOOLS_LIST = 'SUCCESS_UPLOAD_SCHOOLS_LIST';
export const ERROR_UPLOAD_SCHOOLS_LIST = 'ERROR_UPLOAD_SCHOOLS_LIST';

export const REQUEST_GET_ASSESS_REPORT = 'REQUEST_GET_ASSESS_REPORT';
export const SUCCESS_GET_ASSESS_REPORT = 'SUCCESS_GET_ASSESS_REPORT';
export const ERROR_GET_ASSESS_REPORT = 'ERROR_GET_ASSESS_REPORT';

export const REQUEST_GET_ASSESS_REPORT_STATUS =
  'REQUEST_GET_ASSESS_REPORT_STATUS';
export const SUCCESS_GET_ASSESS_REPORT_STATUS =
  'SUCCESS_GET_ASSESS_REPORT_STATUS';
export const ERROR_GET_ASSESS_REPORT_STATUS = 'ERROR_GET_ASSESS_REPORT_STATUS';

export const REQUEST_GET_ASSESS_REPORT_DROPDOWN_INFO =
  'REQUEST_GET_ASSESS_REPORT_DROPDOWN_INFO';
export const SUCCESS_GET_ASSESS_REPORT_DROPDOWN_INFO =
  'SUCCESS_GET_ASSESS_REPORT_DROPDOWN_INFO';
export const ERROR_GET_ASSESS_REPORT_DROPDOWN_INFO =
  'ERROR_GET_ASSESS_REPORT_DROPDOWN_INFO';

export const REQUEST_UPLOAD_EMAIL_DOMAINS = 'REQUEST_UPLOAD_EMAIL_DOMAINS';
export const SUCCESS_UPLOAD_EMAIL_DOMAINS = 'SUCCESS_UPLOAD_EMAIL_DOMAINS';
export const ERROR_UPLOAD_EMAIL_DOMAINS = 'ERROR_UPLOAD_EMAIL_DOMAINS';

export const REQUEST_GET_EMAIL_DOMAINS = 'REQUEST_GET_EMAIL_DOMAINS';
export const SUCCESS_GET_EMAIL_DOMAINS = 'SUCCESS_GET_EMAIL_DOMAINS';
export const ERROR_GET_EMAIL_DOMAINS = 'ERROR_GET_EMAIL_DOMAINS';

// CMS
export const REQUEST_GET_CMS_MEETINGS = 'REQUEST_GET_CMS_MEETINGS';
export const SUCCESS_GET_CMS_MEETINGS = 'SUCCESS_GET_CMS_MEETINGS';
export const ERROR_GET_CMS_MEETINGS = 'ERROR_GET_CMS_MEETINGS';

export const REQUEST_GET_CMS_MEETING_BY_ID = 'REQUEST_GET_CMS_MEETING_BY_ID';
export const SUCCESS_GET_CMS_MEETING_BY_ID = 'SUCCESS_GET_CMS_MEETING_BY_ID';
export const ERROR_GET_CMS_MEETING_BY_ID = 'ERROR_GET_CMS_MEETING_BY_ID';

export const REQUEST_CREATE_CMS_MEETING = 'REQUEST_CREATE_CMS_MEETING';
export const SUCCESS_CREATE_CMS_MEETING = 'SUCCESS_CREATE_CMS_MEETING';
export const ERROR_CREATE_CMS_MEETING = 'ERROR_CREATE_CMS_MEETING';

export const REQUEST_DELETE_CMS_MEETING_BY_ID =
  'REQUEST_DELETE_CMS_MEETING_BY_ID';
export const SUCCESS_DELETE_CMS_MEETING_BY_ID =
  'SUCCESS_DELETE_CMS_MEETING_BY_ID';
export const ERROR_DELETE_CMS_MEETING_BY_ID = 'ERROR_DELETE_CMS_MEETING_BY_ID';

export const REQUEST_UPLOAD_CMS_MEETINGS_DATA =
  'REQUEST_UPLOAD_CMS_MEETINGS_DATA';
export const SUCCESS_UPLOAD_CMS_MEETINGS_DATA =
  'SUCCESS_UPLOAD_CMS_MEETINGS_DATA';
export const ERROR_UPLOAD_CMS_MEETINGS_DATA = 'ERROR_UPLOAD_CMS_MEETINGS_DATA';

export const REQUEST_GET_COMM_MEM_BY_ID = 'REQUEST_GET_COMM_MEM_BY_ID';
export const SUCCESS_GET_COMM_MEM_BY_ID = 'SUCCESS_GET_COMM_MEM_BY_ID';
export const ERROR_GET_COMM_MEM_BY_ID = 'ERROR_GET_COMM_MEM_BY_ID';

export const REQUEST_DOWNLOAD_MEETING_TEMP = 'REQUEST_DOWNLOAD_MEETING_TEMP';
export const SUCCESS_DOWNLOAD_MEETING_TEMP = 'SUCCESS_DOWNLOAD_MEETING_TEMP';
export const ERROR_DOWNLOAD_MEETING_TEMP = 'ERROR_DOWNLOAD_MEETING_TEMP';

export const REQUEST_EXPORT_CMS_MEETINGS = 'REQUEST_EXPORT_CMS_MEETINGS';
export const SUCCESS_EXPORT_CMS_MEETINGS = 'SUCCESS_EXPORT_CMS_MEETINGS';
export const ERROR_EXPORT_CMS_MEETINGS = 'ERROR_EXPORT_CMS_MEETINGS';

export const REQUEST_BULK_DELETE_CMS_MEETING_BY_IDS =
  'REQUEST_BULK_DELETE_CMS_MEETING_BY_IDS';
export const SUCCESS_BULK_DELETE_CMS_MEETING_BY_IDS =
  'SUCCESS_BULK_DELETE_CMS_MEETING_BY_IDS';
export const ERROR_BULK_DELETE_CMS_MEETING_BY_IDS =
  'ERROR_BULK_DELETE_CMS_MEETING_BY_IDS';

export const REQUEST_EXPORT_ASSESSMENT_CANDIDATES =
  'REQUEST_EXPORT_ASSESSMENT_CANDIDATES';
export const SUCCESS_EXPORT_ASSESSMENT_CANDIDATES =
  'SUCCESS_EXPORT_ASSESSMENT_CANDIDATES';
export const ERROR_EXPORT_ASSESSMENT_CANDIDATES =
  'ERROR_EXPORT_ASSESSMENT_CANDIDATES';

export const REQUEST_GET_PS_TSCORES_IMAGES = 'REQUEST_GET_PS_TSCORES_IMAGES';
export const SUCCESS_GET_PS_TSCORES_IMAGES = 'SUCCESS_GET_PS_TSCORES_IMAGES';
export const ERROR_GET_PS_TSCORES_IMAGES = 'ERROR_GET_PS_TSCORES_IMAGES';

export const REQUEST_GET_MEETINGS_REPORT = 'REQUEST_GET_MEETINGS_REPORT';
export const SUCCESS_GET_MEETINGS_REPORT = 'SUCCESS_GET_MEETINGS_REPORT';
export const ERROR_GET_MEETINGS_REPORT = 'ERROR_GET_MEETINGS_REPORT';

export const REQUEST_UPLOAD_CAND_NRIC = 'REQUEST_UPLOAD_CAND_NRIC';
export const SUCCESS_UPLOAD_CAND_NRIC = 'SUCCESS_UPLOAD_CAND_NRIC';
export const ERROR_UPLOAD_CAND_NRIC = 'ERROR_UPLOAD_CAND_NRIC';

export const REQUEST_GET_CAND_ASSESS_STATUS_THIRD_PARTY =
  'REQUEST_GET_CAND_ASSESS_STATUS_THIRD_PARTY';
export const SUCCESS_GET_CAND_ASSESS_STATUS_THIRD_PARTY =
  'SUCCESS_GET_CAND_ASSESS_STATUS_THIRD_PARTY';
export const ERROR_GET_CAND_ASSESS_STATUS_THIRD_PARTY =
  'ERROR_GET_CAND_ASSESS_STATUS_THIRD_PARTY';

export const REQUEST_UPLOAD_TEST_BOOKING_FILE =
  'REQUEST_UPLOAD_TEST_BOOKING_FILE';
export const SUCCESS_UPLOAD_TEST_BOOKING_FILE =
  'SUCCESS_UPLOAD_TEST_BOOKING_FILE';
export const ERROR_UPLOAD_TEST_BOOKING_FILE = 'ERROR_UPLOAD_TEST_BOOKING_FILE';

export const REQUEST_EXPORT_CAND_TEST_BOOKING =
  'REQUEST_EXPORT_CAND_TEST_BOOKING';
export const SUCCESS_EXPORT_CAND_TEST_BOOKING =
  'SUCCESS_EXPORT_CAND_TEST_BOOKING';
export const ERROR_EXPORT_CAND_TEST_BOOKING = 'ERROR_EXPORT_CAND_TEST_BOOKING';

export const REQUEST_UPLOAD_TEST_BOOKING_USAGE_REPORT =
  'REQUEST_UPLOAD_TEST_BOOKING_USAGE_REPORT';
export const SUCCESS_UPLOAD_TEST_BOOKING_USAGE_REPORT =
  'SUCCESS_UPLOAD_TEST_BOOKING_USAGE_REPORT';
export const ERROR_UPLOAD_TEST_BOOKING_USAGE_REPORT =
  'ERROR_UPLOAD_TEST_BOOKING_USAGE_REPORT';

export const REQUEST_GENERATE_YEARLY_APPL_DATA =
  'REQUEST_GENERATE_YEARLY_APPL_DATA';
export const SUCCESS_GENERATE_YEARLY_APPL_DATA =
  'SUCCESS_GENERATE_YEARLY_APPL_DATA';
export const ERROR_GENERATE_YEARLY_APPL_DATA =
  'ERROR_GENERATE_YEARLY_APPL_DATA';

export const REQUEST_CANDIDATE_REPORT_EMAILS =
  'REQUEST_CANDIDATE_REPORT_EMAILS';
export const SUCCESS_CANDIDATE_REPORT_EMAILS =
  'SUCCESS_CANDIDATE_REPORT_EMAILS';
export const ERROR_CANDIDATE_REPORT_EMAILS = 'ERROR_CANDIDATE_REPORT_EMAILS';

export const REQUEST_TRIGGER_RETAKE_FOR_VI = 'REQUEST_TRIGGER_RETAKE_FOR_VI';
export const SUCCESS_TRIGGER_RETAKE_FOR_VI = 'SUCCESS_TRIGGER_RETAKE_FOR_VI';
export const ERROR_TRIGGER_RETAKE_FOR_VI = 'ERROR_TRIGGER_RETAKE_FOR_VI';

export const REQUEST_PSC_PEER_FEEDBACK_EMAILS =
  'REQUEST_PSC_PEER_FEEDBACK_EMAILS';
export const SUCCESS_PSC_PEER_FEEDBACK_EMAILS =
  'SUCCESS_PSC_PEER_FEEDBACK_EMAILS';
export const ERROR_PSC_PEER_FEEDBACK_EMAILS = 'ERROR_PSC_PEER_FEEDBACK_EMAILS';

export const REQUEST_UPLOAD_PS_BRIEFING_DECK_DOC =
  'REQUEST_UPLOAD_PS_BRIEFING_DECK_DOC';
export const SUCCESS_UPLOAD_PS_BRIEFING_DECK_DOC =
  'SUCCESS_UPLOAD_PS_BRIEFING_DECK_DOC';
export const ERROR_UPLOAD_PS_BRIEFING_DECK_DOC =
  'ERROR_UPLOAD_PS_BRIEFING_DECK_DOC';

export const REQUEST_GET_PERSONAL_SUMMARY = 'REQUEST_GET_PERSONAL_SUMMARY';
export const SUCCESS_GET_PERSONAL_SUMMARY = 'SUCCESS_GET_PERSONAL_SUMMARY';
export const ERROR_GET_PERSONAL_SUMMARY = 'ERROR_GET_PERSONAL_SUMMARY';

export const REQUEST_SEARCH_AI_INSIGHTS = 'REQUEST_SEARCH_AI_INSIGHTS';
export const SUCCESS_SEARCH_AI_INSIGHTS = 'SUCCESS_SEARCH_AI_INSIGHTS';
export const ERROR_SEARCH_AI_INSIGHTS = 'ERROR_SEARCH_AI_INSIGHTS';
